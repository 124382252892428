<template>
    <div class="container container-piperun">
        <div class="row">
            <div class="col-sm-12">
                <h2>Sobre o Aplicativo</h2>
                <hr />
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div class="media">
                    <div class="media-body">
                        <img src="../assets/whatsapp.jpg" alt="WhatsApp" class="img-responsive img-thumbnail" />
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="well">
                    <p>
                        Criamos uma aplicação que servirá de ajuda para o suporte ao cliente
                        PipeRun.
                    </p>
                    <p>
                        O objetivo é simplificar o atendimento e realizar as demandas mais
                        recorrentes dos clientes.
                    </p>
                    <p>Dúvidas, favor entrar em contato clicando no botão abaixo.</p>
                    <div class="contact">
                        <a class="btn btn-primary" :href="mail" target="_blank">
                            <i class="glyphicon glyphicon-send"></i> Entrar em Contato
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About',
    data() {
        return {
            mail: 'mailto:yuri@crmpiperun.com'
        }
    }
}
</script>

<style>
    .contact {
        margin: 25px 0;
    }
</style>